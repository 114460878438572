<template>
  <v-layout column style="width: 100%; height: 100%; overflow-y: hidden">
    <Message />
    <v-container style="max-width: 1640px; height: 100%; overflow: hidden; position: relative">
      <v-layout align-center class="fill-height">
        <div style="height: 85vh; width: 100%; background: var(--v-bgContainer-base); border-radius: 12px">
          <v-overlay :value="loading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-layout class="fill-height overflow-y-hidden px-5" column>
            <div
              style="flex: none; height: 88px; width: 100%; text-align: center; border-bottom: 1px solid rgba(93, 101, 136, 0.5)"
            >
              <v-layout align-center class="fill-height px-5">
                <h3 class="text-uppercase">USER MANAGEMENT</h3>
                <v-spacer />
              </v-layout>
            </div>
            <div v-if="users && users.length" class="pt-5">
              <div class="mb-5 d-flex justify-space-between">
                <div style="width: 500px">
                  <v-text-field
                    v-model="keyword"
                    append-icon="mdi-magnify"
                    background-color="#2F324180"
                    dense
                    hide-details
                    label="Search"
                    placeholder="Search"
                    rounded
                    solo
                    @change="getListUsers"
                  >
                  </v-text-field>
                </div>
                <v-btn color="primary" rounded @click="() => $refs.createDialog.showCreate()">
                  <v-icon left>icon-add_user</v-icon>
                  Create user
                </v-btn>
              </div>
              <v-simple-table>
                <v-data-table
                  :headers="headers"
                  :items="users"
                  :items-per-page="5"
                  class="elevation-1"
                  hide-default-footer
                >
                  <template v-slot:[`item.name`]="{ item }">
                    <div class="d-flex align-center">
                      <v-avatar size="36" style="background: #3F51B5;">
                        <img v-if="item.avatar" :src="item.avatar" alt="Avatar" />
                        <span class="white--text text-h6">{{ item.name[0] }}</span>
                      </v-avatar>
                      <div class="ml-3">
                        <div>{{ item.name }}</div>
                        <div style="font-size: 12px;">Role: {{ item.role }}</div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:[`item.active`]="{ item }">
                    <div
                      v-if="currentUser.uuid !== item.uuid"
                      class="d-flex align-center justify-center"
                      @click="showConfirmUser(item)"
                    >
                      <v-switch v-model="item.active" color="success" disabled flat></v-switch>
                    </div>
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <div class="d-flex align-center justify-center">
                      <v-btn color="pink" icon @click="showEdit(item)">
                        <v-icon>icon-edit_square</v-icon>
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
              </v-simple-table>
              <div class="mt-3 d-flex justify-end">
                <v-pagination
                  v-model="page"
                  :length="totalPage"
                  :total-visible="10"
                  circle
                  @input="handChangePage"
                ></v-pagination>
              </div>
            </div>

            <div v-else class="d-flex flex-column align-center justify-center" style="width: 100%; height: 300px">
              <div class="pb-5">
                Your are not in an Organization. Please create your Organization to manage user member
              </div>
              <v-btn class="mt-5" color="primary" @click="() => $refs.organizationDialog.showCreate()">
                Create Organization
              </v-btn>
            </div>
          </v-layout>
        </div>
      </v-layout>
    </v-container>

    <CreateEdit ref="createDialog" :roles="roles" />
    <!-- <DeleteDialog ref='deleteDialog' :submit-delete='deleteProject' /> -->
    <ConfirmDialog ref="confirmDialog" />
    <CreateOrganization ref="organizationDialog" />
  </v-layout>
</template>

<script>
import Message from '@/components/Message.vue'
import { activeDeactive, getRole, getUsers } from '@/api/user'
import ConvertDate from '@/utils/convertDate'
import DeleteDialog from '@/components/DeleteDialog.vue'
import ThemeSwitcher from '@/layouts/components/ThemeSwitcher.vue'
import AppBarUserMenu from '@/layouts/components/AppBarUserMenu.vue'
import CreateEdit from './CreateEditUser.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import { mapState } from '@/store/ults'
import CreateOrganization from '@/views/pages/account-settings/CreateOrganization.vue'
import { deleteProject } from '@/api/project-api'

export default {
  name: 'User',
  components: {
    AppBarUserMenu,
    ThemeSwitcher,
    DeleteDialog,
    Message,
    CreateEdit,
    ConfirmDialog,
    CreateOrganization,
  },
  data() {
    return {
      ConvertDate,
      keyword: undefined,
      loading: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Created at', value: 'created_at', sortable: false },
        {
          text: 'Active',
          value: 'active',
          align: 'center',
        },
        { text: 'Edit', value: 'action', align: 'center', sortable: false },
      ],
      users: [],
      page: 1,
      perPage: 5,
      totalPage: 1,
      roles: [],
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  mounted() {
    this.getListUsers()
    this.getUserRole()
  },
  methods: {
    async getListUsers() {
      try {
        this.loading = true
        const res = await getUsers({
          per_page: this.perPage,
          page: this.page,
          search: this.keyword,
        })
        this.users = res.data.data
        this.totalPage = res.data.last_page
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    showConfirmUser(item) {
      const title = item.active ? 'Deacitve user' : 'Active user'
      this.$refs.confirmDialog.openDialog({
        title: title,
        content: `Do you want to ${title} <b>${item.name}</b>`,
        action: async () => {
          await activeDeactive(item.uuid)
          await this.getListUsers()
        },
      })
    },
    async getUserRole() {
      let data = await getRole()
      this.roles = data.data
    },
    async handChangePage(page) {
      this.page = page
      this.getListUsers()
    },
    async deleteProject(project) {
      try {
        this.loading = true
        await deleteProject(project.uuid)
        await this.getListProject()
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    showEdit(data) {
      this.$refs.createDialog.showEdit(data)
    },
  },
}
</script>

<style scoped></style>
